import React from 'react';
import { useSelector, useDispatch } from 'react-redux'; 

export function Plans(props) {
  const {recipes } = useSelector(state => state.recipes)

  const meals = {
    breakfast: [],
    lunch: [],
    dinner: [],
    snack: [], 
  };
  const getRandomRecipe = (recipes) => {
    const randomIndex = Math.floor(Math.random() * recipes.length);
    return recipes[randomIndex];
  };
  
  const addRecipesToMeals = (meals, recipes, calorieLimit) => {
    let totalCalories = 0;
    
    const step = 100; // погрешность
    const maxCals = calorieLimit; // максимальная калорийность
    console.log(maxCals)
    const mealsCopy = JSON.parse(JSON.stringify(meals));
    const mealKeys = Object.keys(mealsCopy);
    const maxAttempts = 100;  // Максимальное количество попыток добавления рецептов
    let count = 0;
    
    while (totalCalories < maxCals - step || totalCalories > maxCals + step && count < maxAttempts) {
      for (let meal of mealKeys) {
      const recipe = getRandomRecipe(recipes);
      if (totalCalories + recipe.calories.calories <= maxCals + step) {
          console.log(mealsCopy[meal])
          mealsCopy[meal].push(recipe);
        totalCalories += recipe.calories.calories;
      }
      count++;
    }
 
    console.log( totalCalories)
}
console.log(mealsCopy)
  };
 
  

 
  return (
    <div className='page-main'>
      <button onClick={()=>addRecipesToMeals(meals, recipes, 1800)}>plans</button>
    </div>
  )
   
}