import React from 'react';
import api from "../../../utils/api"; 
import './Analysis.css';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';

export function Analysis(props) {
   const [data, setData] = React.useState([])
    React.useEffect(() => {
        api.getAnalysis()
        .then((res)=> {
           console.log(res)
            setData(res)
        })
    }, [])
    
    
    
  return (
    <main className='content section__row'>
      <h1></h1> 
      <section className='analiys__section'>
        <h2>Типы питания</h2>
        {Object.values(data).length > 0 && data.diets.length > 0 && 
       <BarChart
       width={500}
       height={300}
       data={data.diets}
       margin={{
         top: 20, right: 30, left: 20, bottom: 5,
       }}
     >
       <CartesianGrid strokeDasharray="3 3" />
       <XAxis dataKey="name" />
       <YAxis />
       <Tooltip />
       <Legend />
       <Bar dataKey="count" fill="#8884d8" />
     </BarChart>
        }
      </section>
      <section className='analiys__section'>
        <h2>Время приготовления</h2>
        {Object.values(data).length > 0 && data.cookingTimes.length > 0 &&  
       <BarChart
       width={500}
       height={300}
       data={data.cookingTimes}
       margin={{
         top: 20, right: 30, left: 20, bottom: 5,
       }}
     >
       <CartesianGrid strokeDasharray="3 3" />
       <XAxis dataKey="time" />
       <YAxis />
       <Tooltip />
       <Legend />
       <Bar dataKey="count" fill="#8884d8" />
     </BarChart>
        }
      </section>
      <section className='analiys__section'>
        <h2>Калорийность</h2>
        <p>Без калорийности: {Object.values(data).length > 0 && data.notcals}</p>
        {Object.values(data).length > 0 && data.cals.length > 0 &&  
       <BarChart
       width={500}
       height={300}
       data={data.cals}
       margin={{
         top: 20, right: 30, left: 20, bottom: 5,
       }}
     >
       <CartesianGrid strokeDasharray="3 3" />
       <XAxis dataKey="cals" />
       <YAxis />
       <Tooltip />
       <Legend />
       <Bar dataKey="count" fill="#8884d8" />
     </BarChart>
        }
      </section>
      <section className='analiys__section'>
        <h2>Приемы пищи</h2>
        {Object.values(data).length > 0 && data.meals.length > 0 &&  
       <BarChart
       width={500}
       height={300}
       data={data.meals}
       margin={{
         top: 20, right: 30, left: 20, bottom: 5,
       }}
     >
       <CartesianGrid strokeDasharray="3 3" />
       <XAxis dataKey="nameRu" />
       <YAxis />
       <Tooltip />
       <Legend />
       <Bar dataKey="count" fill="#8884d8" />
     </BarChart>
        }
      </section>
      <section className='analiys__section'>
        <h2>Оборудование</h2>
        {Object.values(data).length > 0 && data.wares.length > 0 &&  
       <BarChart
       width={500}
       height={300}
       data={data.wares}
       margin={{
         top: 20, right: 30, left: 20, bottom: 5,
       }}
     >
       <CartesianGrid strokeDasharray="3 3" />
       <XAxis dataKey="name" />
       <YAxis />
       <Tooltip />
       <Legend />
       <Bar dataKey="count" fill="#8884d8" />
     </BarChart>
        }
      </section>
      <section className='analiys__section'>
        <h2>Период готовки</h2>
        {Object.values(data).length > 0 && data.period.length > 0 &&  
       <BarChart
       width={500}
       height={300}
       data={data.period}
       margin={{
         top: 20, right: 30, left: 20, bottom: 5,
       }}
     >
       <CartesianGrid strokeDasharray="3 3" />
       <XAxis dataKey="period" />
       <YAxis />
       <Tooltip />
       <Legend />
       <Bar dataKey="count" fill="#8884d8" />
     </BarChart>
        }
      </section>
      <section className='analiys__section'>
        <h2>Продукты</h2>
        <div className='analisys__block'>
        {Object.values(data).length > 0 && data.product.length > 0 &&  
          data.product.map(produc => (
              <div className='analisys__item'>
              <p>{produc.nameRu}</p>
              <p>{produc.count}</p>
              </div>
          ))
        }
        </div>
      </section>
      <section className='analiys__section'>
        <h2>Лайки</h2>
        <div className='analisys__block'>
        {Object.values(data).length > 0 && data.likes.length > 0 &&  
          data.likes.map(like => (
              <div className='analisys__item'>
              <p>{like.name}</p>
              <p>{like.count}</p>
              </div>
          ))
        }
        </div>
      </section>
    </main>
)
   
}
