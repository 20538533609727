//Поисковая строка из выпадающего списка и добавлением в инпут после клика

import React from 'react';
import { AutoComplete } from '../../../utils/utils.js'
import { BrandPacking } from '../'

export function SearchItem(props) {

  console.log(props.choiceIngredient)
  return (
    <div>
      {props.choiceIngredient && props.choiceIngredient.length > 0 &&
        <ul className='brands'>
          {props.choiceIngredient.map((item, index)=> (
            <BrandPacking 
             handleDelete={props.handleDelete}
             onClick={props.handleClickPacking}
             item={item}
             key={index}
            />
            
          ))}
        </ul>
      }
    <label className={`form__label form__label_type_${props.type}`} htmlFor={props.name} >
      <input 
        type={props.type}
        value={props.value || ''} 
        onChange={props.handleChangeInput} 
        placeholder={props.placeholder} 
        className={`form__input form__input_type_${props.type}`}  
        name={props.name} 
        id={props.id}
      />
      {/*props.ingError && <span className="add-form__item-error">{props.ingError}</span> */}
        <span className='add-recipe__autocomplete'>
        {props.filtrMassiv.length > 0 && props.hidden &&
          props.filtrMassiv.map((item, index) => (
          <AutoComplete 
            key={index} 
            item={item} 
            name={props.name}
            onClick={props.handleAutoText} />
          ))
        }
        </span>
      </label>
    </div>
  )
     
  }