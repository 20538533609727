import React from 'react';
import {IngredientsTable, AddEditIngredient, Search} from '../../organisms'
import { useSelector } from 'react-redux'
import './Ingredients.css';
import { filtrSearchArr, sortCategory, sortName, sortArrDate } from '../../../utils/utils.js'

export function Ingredients(props) {
  const { ingredients } = useSelector(state => state.ingredients)
  const PAGE_SIZE = 20;
  const [showModal, setShowModal] = React.useState(false)
  const [visual, setVisual] = React.useState([])
  const [showModalEdit, setShowModalEdit] = React.useState(false)
  const [editCard, setEditCard] = React.useState(false)
  const [copyCard, setCopyCard] = React.useState(false)
  const [filtrmassiv, setFiltrMassiv] = React.useState([])
  const [keyword, setKeyword] = React.useState('')
  const [currentPage, setCurrentPage] = React.useState(1) 
  const allPages = Math.ceil(ingredients.length/PAGE_SIZE)
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const pages = Array.from({length: allPages}, (_, i) => i + 1) 

  React.useEffect(() => {
    setFiltrMassiv(ingredients.slice(startIndex, endIndex))
  }, [ingredients]);
 
  React.useEffect(()=> { 
    if(currentPage) {  
      setFiltrMassiv(ingredients.slice(startIndex, endIndex))
    } 
  },[currentPage])


  function handleSortName() {
    setFiltrMassiv(sortName(ingredients))
  }

  function handleSortCategory() {
    setFiltrMassiv(sortCategory(ingredients))
  }

  function handleAddIngredient() {
    setShowModal(true)
  }

  function handleCloseModal() {
    setShowModal(false)
    setShowModalEdit(false)
    setEditCard(false)
    setCopyCard(false)
  }
 
  function handleEdit(card) {
    setShowModalEdit(true)
    setEditCard(card)
    setCopyCard(false)
  }

  function handleCopy(card) {
    setShowModalEdit(true)
    setEditCard(card)
    setCopyCard(true)
  }

    //Слушатель на ввод поисковых слов
    function handleChangeInput(e) {
      setKeyword(e.target.value);
      handleFiltrClick(e.target.value)
    }
  
    //Поиск в инпуте по ингридиенту
    function handleFiltrClick(keyword) {
      if(keyword!=='') {
      const newMassiv = filtrSearchArr(ingredients, keyword)
      setFiltrMassiv(newMassiv)
      } else {
        setFiltrMassiv(ingredients)
      }
    }
   //Счетчик изменения страниц
  function handleCounter(page) { 
    setCurrentPage(page)
  }


  return (
    <main className='content'>
      <Search onSearch={handleChangeInput} keyword={keyword}/>
      <button onClick={handleAddIngredient} className='my-recipe__add-button'>Добавить ингредиент</button>
      <table className='table'>
        <caption> Ингридиенты</caption>
        <thead className='table__thead'>
          <tr className='table__tr'>
            <th className='table__th'>Название<span className='gg-sort item__link' onClick={handleSortName}></span></th>
            <th className='table__th'>Категория<span className='gg-sort item__link' onClick={handleSortCategory}></span></th> 
            <th className='table__th'>Указать цену</th>
            <th className='table__th'>Редактировать</th>
          </tr>
        </thead>
      <tbody>
      {filtrmassiv &&
       filtrmassiv.map((ingredient)=> (
        <IngredientsTable 
          ingredient={ingredient} 
          key={ingredient._id}  
          editIngredient={handleEdit}
          copyIngredient={handleCopy} 
          />
       ))
     }
       </tbody>
      </table>
     <div className='pages'>
      {pages.map((page, index)=> 
        <span 
            className={`page__number ${currentPage === page ? 'page__current' : ''}`}
              key={index} 
              onClick={()=>handleCounter(page)}>{page}</span>
            )}
        </div>
    
    <AddEditIngredient 
      onClose={handleCloseModal} 
      onError={props.onError} 
      postIng={props.postIng} 
      isOpenEdit={showModalEdit} 
      isOpen={showModal} 
      card={editCard && editCard}
      copyCard={copyCard && copyCard}
    /> 
    </main>
  )
   
}